import React, { useEffect } from 'react';
import Container from '@mui/material/Container';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import FormControl from '@mui/material/FormControl';
import Button from '@mui/material/Button';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import ExcellentExport from 'excellentexport';
import RefreshIcon from '@mui/icons-material/Refresh';
import CircularProgress from '@mui/material/CircularProgress';

import { useStyles } from "./styles";
import {get} from "./commons";


function Row({ index,row,managers,projects,employees,employee,client,workOrder,manager,activity,workOrders,prodItems }) {
  const displayDate = (date) => {
    let newDate = new Date(date);
    if (newDate) return newDate.toLocaleDateString('es-ES')
  }
  const showemployeenmae = (row) => {
    if (row) {
      let emp = employees.filter((e)=>e.code===row);//console.log(emp)
      if (emp && emp[0] && emp[0].name) return emp[0].name;
    }
  }
  let rowData = JSON.parse(row.content).reduce((a,c)=>{
    a[c.id] = c.value;
    return a
  },{});
  return (
    <TableRow>
    {prodItems.map(e=>
      <TableCell key={e.id}> {rowData[e.id]}</TableCell>
    )}
    </TableRow>
  );
}

export default function WorkReport({biz,shiftsigned,managers,projects,iamamanager,iamadmin,isMobileSize,globalVariables,changeGlobalVariables}) {
  const classes = useStyles();

  const [employees, setEmployees] = React.useState([]);
  const [employee, setEmployee] = React.useState(globalVariables.proddataReview_employee ? globalVariables.proddataReview_employee : null);
  const [clients, setClients] = React.useState([]);
  const [client, setClient] = React.useState(globalVariables.proddataReview_client ? globalVariables.proddataReview_client : null);
  const [workOrders, setWorkOrders] = React.useState([]);
  const [workOrder, setWorkOrder] = React.useState(globalVariables.proddataReview_workOrder ? globalVariables.proddataReview_workOrder : null);
  const [manager, setManager] = React.useState(globalVariables.proddataReview_manager ? globalVariables.proddataReview_manager : null);
  const [activity, setActivity] = React.useState();
  const [prodData, setProdData] = React.useState();
  const [prodItems, setProdItems] = React.useState([]);
  const [updatedTotal, setUpdatedTotal] = React.useState();
  const [managers_filtered, set_managers_filtered] = React.useState(managers);
  const [clients_filtered, set_clients_filtered] = React.useState([]);
  const [wo_filtered, set_wo_filtered] = React.useState([]);

  const padLeft = (n) => ("00" + n).slice(-2);
  const today = new Date();
  const todayFormated = today.getFullYear() + "-" + padLeft(1+today.getMonth()) + "-" + padLeft(today.getDate());
  const [startDate, setStartDate] = React.useState(globalVariables.proddataReview_startDate ? globalVariables.proddataReview_startDate : todayFormated);
  const [endDate, setEndDate] = React.useState(globalVariables.proddataReview_endDate ? globalVariables.proddataReview_endDate : todayFormated);

  const displayDate = (date) => {
    let newDate = new Date(date);
    if (newDate) return newDate.toLocaleDateString('es-ES')
  }

  const selectManager = (event, newValue) => {
    setManager(newValue);
    changeGlobalVariables("proddataReview_manager", newValue);
  }
  const selectClient = (event, newValue) => {
    if (clients_filtered?.length!==clients?.length) {
      set_clients_filtered(clients);
      set_managers_filtered(managers);
      set_wo_filtered(workOrders);
      setWorkOrder();
    }
    else {
      set_wo_filtered(workOrders.filter(e=>!newValue || e.client === newValue.code))
      setClient(newValue);
      changeGlobalVariables("proddataReview_client", newValue);
    }
  }
  const selectEmployee = (event, newValue) => {
    setEmployee(newValue);
    changeGlobalVariables("proddataReview_employee", newValue);
  }
  const selectWorkOrder = (event, newValue) => {
    setUpdatedTotal();
    setWorkOrder(newValue);
    changeGlobalVariables("proddataReview_workOrder", newValue);
    let newClient = clients.filter(e=>newValue?.client === e.code);
    if (newClient.length === 1) setClient(newClient[0]);
    else setClient();
    let newProject = projects?.filter(e=>newValue?.work_report === e.work_report);
    if (newProject.length === 1) {
      let newProjectKeys = Object.keys(newProject[0]);
      if (newProjectKeys.length > 0) {
        let newmanagerskeys = newProjectKeys.filter(e=>e.indexOf("_manager") > -1);
        let managersIDs = newmanagerskeys.map(e=>newProject[0][e]);
        set_managers_filtered(managers.filter(e=>managersIDs.includes(e.id)));
        let newclient = clients.filter(e=>e.name===newProject[0].client);
        set_clients_filtered(newclient);
        let newwo = clients.filter(e=>e.name===newProject[0].client);
      }
      else {
        set_managers_filtered(managers);
        set_clients_filtered(clients);
        set_wo_filtered(workOrders);
      }
    }
    else {
      set_managers_filtered(managers);
      set_clients_filtered(clients);
      set_wo_filtered(workOrders);
    }
  }
  const changeStartDate = (e) => {
    let newValue = e.target.value;
    setStartDate(newValue);
    changeGlobalVariables("proddataReview_startDate", newValue);
  }
  const changeEndDate = (e) => {
    let newValue = e.target.value;
    setEndDate(newValue);
    changeGlobalVariables("proddataReview_endDate", newValue);
  }

  const searchDailyShifts = () => {
    setProdData();
    let query = `/server/request/getprodfulldata?startdate=${startDate}&enddate=${endDate}`;
    if (employee && employee.code) query += `&employee=${employee.code}&employeesub=${employee.subcode}`;
    if (client && client.code) query += `&client=${client.code}`;
    if (workOrder && workOrder.work_report) query += `&wo=${workOrder.work_report}`;
    if (manager && manager.id) query += `&manager=${manager.id}`;
    get(query)
    .then((response) => {
      if (response.activity?.length > 0) setActivity(response.activity);
      else setActivity();
      if (response.prod) {
        let newProdSets =  response.prod.map(e => {
          let newContent = [];
          response.sets?.forEach(f => {
            if (f.id === e.id) newContent.push({id:f.row, value:f.content});
          })
          e.content = JSON.stringify(newContent);
          return e;
        });

        setProdData(response.prod);
      }
    })
  }

  useEffect(()=>{
    get('/server/request/getemployees')
    .then((response) => {
      if (response && response.employees?.length > 0) {
        setEmployees(response.employees);
      }
    });
    get(`/server/request/getworkorders?clients=true`)
    .then((response) => {
      if (response && response.wo?.length > 0) {
        setWorkOrders(response.wo);
        set_wo_filtered(response.wo);
      }
    });
    get(`/server/request/getclients?valsegment`)
    .then((response) => {
      if (response && response.clients?.length > 0) {
        setClients(response.clients);
        set_clients_filtered(response.clients);
      }
    });
    get(`/server/request/getproddataitems`)
    .then((response) => {
      if (response && response.proditems?.length > 0) {
        setProdItems(response.proditems)
      }
    });
  },[]);

  useEffect(()=>{
    searchDailyShifts()
  },[employee,client,manager,workOrder,startDate,endDate]);

  const downloadFile = () => {
    let options = {
       openAsDownload: true,
       format: 'xlsx',
       filename: `revision_produccion`
    };
    let fullDatSet2Export = prodData.map(row=>{
      let rowData = JSON.parse(row.content).reduce((a,c)=>{
        a[c.id] = c.value;
        return a
      },{});
      return prodItems.map(e=>
        rowData[e.id]
      )
    });
    let otherItems = [];
    if (workOrder) otherItems.push(['ORDEN DE TRABAJO',workOrder.work_report]);
    if (employee) otherItems.push(['EMPLEADO',employee.name]);
    if (client) otherItems.push(['CLIENTE',client.name]);
    if (manager) otherItems.push(['ENCARGADO',manager.name]);
    if (startDate) otherItems.push(['FECHA INICIO',startDate]);
    if (endDate) otherItems.push(['FECHA FIN',endDate]);
    otherItems.push(prodItems.map(e=>e.name));
    let sheets =   [
         {
             name: `datos de producción`,
             from: {
                 table: String/Element,
                 array: [
                   ...otherItems,
                   ...fullDatSet2Export
                 ]
             }
         }
     ]
    ExcellentExport.convert(options, sheets);
  }

  const showemployeenmae = (row) => {
    if (row) {
      let emp = employees.filter((e)=>e.code===row);//console.log(emp)
      if (emp && emp[0] && emp[0].name) return emp[0].name;
    }
  }
  const showcllientnmae = (row) => {
    if (row) {
      let emp = clients_filtered.filter((e)=>e.code===row);//console.log(emp)
      if (emp && emp[0] && emp[0].name) return emp[0].name;
    }
  }
  return (
    <div className={classes.contentBorder}>
      <Grid container direction="row" justify="center" alignContent="center" alignItems="center" spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h5" color="textSecondary">
            Revisión de datos de producción
          </Typography>
        </Grid>
        <Grid item xs={isMobileSize ? 6 : 3} id="search">
          <Autocomplete
            disablePortal
            fullWidth
            id="combo-box-wo"
            value={workOrder}
            onChange={selectWorkOrder}
            options={wo_filtered}
            getOptionLabel={(option) => `${option.work_report} ${option.description}`}
            className={wo_filtered?.length!==workOrders?.length ? classes.selectBlue : null}
            renderInput={(params) => <TextField {...params} label="Orden de trabajo" placeholder="comienza a teclear el nombre..."/>}
          />
        </Grid>
        {employees &&
          <Grid item xs={isMobileSize ? 6 : 3} id="search">
            <Autocomplete
              disablePortal
              fullWidth
              id="combo-box-employees"
              value={employee}
              onChange={selectEmployee}
              options={employees}
              getOptionLabel={(option) => `${option.name} (${option.code})`}
              renderInput={(params) => <TextField {...params} label="Empleado" placeholder="comienza a teclear el nombre..."/>}
            />
          </Grid>
        }
        <Grid item xs={isMobileSize ? 6 : 3} id="search">
          <Autocomplete
            disablePortal
            fullWidth
            id="combo-box-clients"
            value={client}
            onChange={selectClient}
            options={clients_filtered}
            getOptionLabel={(option) => `${option.code} ${option.name}`}
            className={clients_filtered?.length!==clients?.length ? classes.selectBlue : null}
            renderInput={(params) => <TextField {...params} label="Cliente" placeholder="comienza a teclear el nombre..."/>}
          />
        </Grid>
        {managers &&
          <Grid item xs={isMobileSize ? 6 : 3} id="search">
            <Autocomplete
              disablePortal
              fullWidth
              id="combo-box-manager"
              value={manager}
              onChange={selectManager}
              options={managers_filtered}
              getOptionLabel={(option) => option.name}
              className={managers_filtered?.length!==managers?.length ? classes.selectBlue : null}
              renderInput={(params) => <TextField {...params} label="Encargados" />}
            />
          </Grid>
        }
        <Grid item xs={isMobileSize ? 6 : 3} id="search-start-date">
          <TextField id="outlined-start-date" label={"Fecha inicio"} variant="outlined" type="date" defaultValue={startDate} onChange={changeStartDate}/>
        </Grid>
        <Grid item xs={isMobileSize ? 6 : 3} id="search-end-date">
          <TextField id="outlined-end-date" label="Fecha fin" variant="outlined" type="date" defaultValue={endDate} onChange={changeEndDate}/>
        </Grid>
        <Grid item xs={isMobileSize ? 6 : 3} id="search" align="center">
          {prodData && prodData?.length > 0 &&
              <Button
                key="download-button"
                variant="outlined"
                onClick={downloadFile}
                startIcon={<img src="/images/file-excel.svg" className={classes.algin2middle20px}/>}
                >
                  Descargar Excel
                </Button>
          }
        </Grid>
        <Grid item xs={12} id="search" align="center">
          {prodData && prodData?.length === 0 &&
              <Typography variant="body" color="textSecondary">
                Sin datos para esta búsqueda
              </Typography>
          }
          {!prodData && <CircularProgress/>}
        </Grid>
        <Grid item xs={12} id="search" align="center">
        {prodData && prodData?.length > 0 &&
            <TableContainer component={Paper} className={classes.prodTable2}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table" stickyHeader>
                <TableHead>
                  <TableRow key="toprow">
                    {prodItems.map(e=>
                      <TableCell key="date" className={classes.greybackground}>{e.name}</TableCell>
                    )}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {prodData.map((row,index) => (
                    <Row
                      key={index}
                      index={index}
                      row={row}
                      managers={managers}
                      projects={projects}
                      employees={employees}
                      employee={employee}
                      client={client}
                      workOrder={workOrder}
                      manager={manager}
                      activity={activity}
                      workOrders={workOrders}
                      prodItems={prodItems}
                      />
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            }
          </Grid>
          <Grid item xs={12} id="search" align="center">
            {1===0 && activity?.length>0 &&
              <Button key="send" variant="contained" onClick={downloadFile}>Descargar en Excel</Button>
            }
          </Grid>
        </Grid>
    </div>
  );

}
